/** @jsx jsx */
import { jsx } from 'theme-ui';
import * as React from 'react';
import Html5Logo from 'assets/svg/tech/html-5.svg';
import Css3Logo from 'assets/svg/tech/css-3.svg';
import JavaScriptLogo from 'assets/svg/tech/javascript.svg';
import RLogo from 'assets/svg/tech/logo-rc.svg';
import MobXLogo from 'assets/svg/tech/mobx.svg';
import TypeScriptLogo from 'assets/svg/tech/typescript-icon.svg';
import GatsbyJSLogo from 'assets/svg/tech/gatsby.svg';
import GraphQLLogo from 'assets/svg/tech/graphql.svg';
import NextLogo from 'assets/svg/tech/nextjs.svg';
import NodeJSLogo from 'assets/svg/tech/nodejs-icon.svg';
import WebpackLogo from 'assets/svg/tech/webpack.svg';
import TailwindLogo from 'assets/svg/tech/tailwindcss-icon.svg';
import AngularLogo from 'assets/svg/tech/angular-icon.svg';
import VueLogo from 'assets/svg/tech/vue.svg';
import AWSLogo from 'assets/svg/tech/aws.svg';
import AzureLogo from 'assets/svg/tech/azure-icon.svg';
import FigmaLogo from 'assets/svg/tech/figma.svg';
import LESSLogo from 'assets/svg/tech/less.svg';
import SASSLogo from 'assets/svg/tech/sass.svg';
import PostCSSLogo from 'assets/svg/tech/postcss.svg';
import JestLogo from 'assets/svg/tech/jest.svg';
import IonicLogo from 'assets/svg/tech/ionic.svg';
import ThemeUILogo from 'assets/images/tech/theme-ui-logo.png';
import StyledComponentsLogo from 'assets/images/tech/styled-components-logo.png';

interface ISkills {
  icon: React.ReactNode,
  name: string
}

interface ITechnologyProps {
  skillLists: string[]
}

const allSkills: ISkills[] = [
  {
    icon: <Html5Logo/>,
    name: 'HTML5',
  },
  {
    icon: <Css3Logo/>,
    name: 'CSS3',
  },
  {
    icon: <JavaScriptLogo/>,
    name: 'JavaScript',
  },
  {
    icon: <RLogo/>,
    name: 'ReactJS',
  },
  {
    icon: <TypeScriptLogo/>,
    name: 'TypeScript',
  },
  {
    icon: <MobXLogo/>,
    name: 'MobX',
  },
  {
    icon: <GatsbyJSLogo/>,
    name: 'Gatsby',
  },
  {
    icon: <GraphQLLogo/>,
    name: 'GraphQL',
  },
  {
    icon: <NextLogo/>,
    name: 'NextJS',
  },
  {
    icon: <NodeJSLogo/>,
    name: 'NodeJS',
  },
  {
    icon: <WebpackLogo/>,
    name: 'Webpack',
  },
  {
    icon: <TailwindLogo/>,
    name: 'TailwindCSS',
  },
  {
    icon: <AngularLogo/>,
    name: 'Angular',
  },
  {
    icon: <VueLogo/>,
    name: 'VueJS',
  },
  {
    icon: <img src={ThemeUILogo} alt={'ThemeUILogo'} title={'ThemeUI'}/>,
    name: 'ThemeUI',
  },
  {
    icon: <img src={StyledComponentsLogo} alt={'StyledComponentsLogo'} title={'StyledComponents'}/>,
    name: 'StyledComponents',
  },
  {
    icon: <AWSLogo/>,
    name: 'AWS',
  },
  {
    icon: <AzureLogo/>,
    name: 'Azure',
  },
  {
    icon: <FigmaLogo/>,
    name: 'Figma',
  },
  {
    icon: <LESSLogo/>,
    name: 'LESS',
  },
  {
    icon: <SASSLogo/>,
    name: 'SASS',
  },
  {
    icon: <PostCSSLogo/>,
    name: 'PostCSS'
  },
  {
    icon: <JestLogo/>,
    name: 'Jest',
  },
  {
    icon: <IonicLogo/>,
    name: 'Ionic',
  },
];

export const Technology = ({ skillLists }: ITechnologyProps) => {

  const mainSkills: ISkills[] = [];

  skillLists.map((skill) => {
    if (allSkills.filter(allSkill => allSkill.name === skill).length < 1) return;
    const currentSkill = allSkills.filter(allSkill => allSkill.name === skill);
    mainSkills.push(...currentSkill);
  });

  return (<div
      sx={{
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <ul
        sx={{
          display: 'flex',
          listStyle: 'none',
          padding: 0,
          margin: 0,
          flexWrap: 'wrap',
        }}
      >
        {mainSkills.map((item, index) => {
            if (!item.name || !item.icon) return;
            return (
              <li
                sx={{
                  margin: '20px 0 0 24px',
                  'svg, img': {
                    maxWidth: '50px',
                    maxHeight: '50px',
                  }
                }}
                title={item.name}
                key={item.name + index}>
                {item.icon}
              </li>
            );
          },
        )}
      </ul>
    </div>
  );
};

export default Technology;
