/** @jsx jsx */
import * as React from 'react';
import { DefaultLayout } from '../components/DefaultLayout';
import { GatsbyHelmet } from '../utils/helmet';
import { Container, Divider, jsx, Button } from 'theme-ui';
import { navigate } from 'gatsby';
import Technology from '../components/Technology';
import Testimonials from '../components/Testimonials';
import { useGtag } from '../utils/utils';

const Index: React.FC = () => {
  return (
    <DefaultLayout>
      <GatsbyHelmet
        title="Home"
        description="Portfolio of Chris Wolmarans - Front-end Developer living in Vancouver B.C."/>
      <Container sx={{ variant: 'landingTopContainer' }}>
        <h6 sx={{ variant: 'link.breadcrumbHeading' }}>
          hello, welcome to my portfiolio.
          <Divider sx={{ variant: 'link.breadcrumbDivider' }}/>
        </h6>
        <Container sx={{ variant: 'landingHeroContainer' }}>
          <h1 sx={{ color: 'primary', fontWeight: 'medium' }}>
            I'm <span sx={{ variant: 'spanHighlight', textDecoration: 'none' }}>Chris Wolmarans</span>,
            a Front-end Developer living in Vancouver BC
          </h1>
          <p><span sx={{ fontWeight: 'bold', color: 'highlight' }}>TL;DR:</span> I design &amp; build beautiful
            hand-crafted websites and user interfaces.</p>
          <Button sx={{ variant: 'buttons.hero', marginRight: '50px' }}
                  onClick={() => {
                    navigate('/work');
                    useGtag(
                      'event',
                      'click',
                      'landing',
                      { name: 'button-hero-work' },
                    );
                  }}
          >My work experience.</Button>
          <Button sx={{ variant: 'buttons.hero' }}
                  onClick={() => {
                    navigate('/projects');
                    useGtag(
                      'event',
                      'click',
                      'landing',
                      { name: 'button-hero-projects' },
                    );
                  }}
          >Check out my latest projects.</Button>
        </Container>
      </Container>
      <Container sx={{ variant: 'landingBelowTheFoldContainer' }}>
        <h2 sx={{ variant: 'landingHeading', fontSize: ['3rem', '7rem', '7rem', '10rem'] }}>About</h2>
        <p>
          I’m a Front-end Developer with extensive experience in the exciting world of UI Design and
          Front-end Web Development.
        </p>
        <p>
          My experience ranges from web application interfaces and services in SaaS industry, to creative
          interactive digital agency work, including the development of high volume E-commerce sites for large
          corporate clients across multiple language regions and territories. </p>
        <p>
          Strong professional visual communication skills, graduated from The Open Window Art Academy in South Africa.
          Skilled in SaaS industry software development with HTML5, CSS3, JavaScript, Responsive Mobile Design.
        </p>
        <p sx={{ pb: 6 }}>
          I’m highly skilled in grasping project guidelines and successfully translating the appropriate visual strategy
          into Graphical User Interfaces that focus on accessibility, usability and standards compliant specifications.
          I function extremely well in a team and love to collaborate and learn in a highly motivated and competitive
          environment.
        </p>
        <h2 sx={{ variant: 'landingHeading' }}>Technology</h2>
        <p>I am most productive with TypeScript (and MobX), ReactJS (or Angular 2+, VueJS), GraphQL in a JAM
          stack, Headless CMS development environment. I am familiar with working in a Full Stack environment,
          but add most value on the Front-End of that spectrum. I use SSG/SSR technologies, especially GatsbyJS,
          when available to build blazing fast web and user interfaces. Experience with both Amazon AWS and Microsoft
          Azure cloud platforms.
        </p>
        <Technology skillLists={[
          'HTML5', 'CSS3', 'JavaScript', 'ReactJS', 'TypeScript', 'MobX', 'Gatsby', 'ThemeUI', 'GraphQL', 'Webpack',
          'NodeJS', 'NextJS', 'TailwindCSS', 'Angular', 'VueJS', 'AWS', 'Azure', 'StyledComponents', 'Figma', 'LESS',
          'SASS', 'PostCSS', 'Jest',
        ]}/>
        <p sx={{ pb: 6 }}>
          I currently use Theme-UI's Styled Components to create "themeable user interfaces based on constraint-based
          design principles". I have also recently used Tailwind CSS, Material-UI, Bootstrap v4 and Bulma CSS frameworks
          on various web and mobile projects.
        </p>
        <h2 sx={{ variant: 'landingHeading' }}>Testimonials</h2>
        <Testimonials/>
        <Button sx={{ variant: 'buttons.hero', mt: 7 }}
                onClick={() => {
                  navigate('/contact');
                  useGtag(
                    'event',
                    'click',
                    'landing',
                    { name: 'button-hero-contact' },
                  );
                }}
        >Say hello.</Button>
      </Container>
    </DefaultLayout>
  );
};

export default Index;
