/** @jsx jsx */
import { jsx, Avatar } from 'theme-ui';
import * as React from 'react';
import Jean from 'assets/images/testimonials/jean.jpeg';
import Stephen from 'assets/images/testimonials/stephen.jpeg';
import { navigate } from 'gatsby';
import { useGtag } from '../utils/utils';

interface ITestimonials {
  profile: string,
  link: string,
  name: string,
  title: string,
  company: string,
  date: string,
  testimonial: string,
}

interface ITestimonialsProps {
  testimonials: ITestimonials[]
}

const testimonials: ITestimonials[] = [
  {
    profile: Jean,
    link: 'https://www.linkedin.com/in/lozanojean',
    name: 'Jean Lozano',
    title: 'CTO',
    company: 'MediaValet',
    date: 'April 13, 2020',
    testimonial: 'Chris not only has the technical chops in front end development but he has an eye for good design and is passionate about amazing UX implementations. He always goes above and beyond the call of duty when faced with tough technical development challenges. Definitely would give him a two thumbs up!',
  },
  {
    profile: Stephen,
    link: 'https://www.linkedin.com/in/stephenhm',
    name: 'Stephen Hayes-McCoy',
    title: 'VP Technology',
    company: 'Communo',
    date: 'April 1, 2020',
    testimonial: 'Chris came in to work with us on an important product launch. He played a huge role in helping us meet our deadline, and was hard working and a great team member. Would wholeheartedly recommend Chris!',
  },
];

export const Testimonials: React.FC = () => {
  return (
    <div sx={{ display: 'flex', flexDirection: ['column','row'], justifyContent: 'space-between'}}>
      {testimonials && testimonials.map((testimonial) => (
          <div
            sx={{maxWidth: ['100%','45%'], marginBottom: 4}}
            key={testimonial.name}>
            <a sx={{ variant: 'link.menu', textDecoration: 'none', display: 'flex', flexDirection: ['column','row'],
              justifyContent: 'flex-start', alignItems: 'center'}}
               aria-label="Link to LinkedIn Recommendation"
               title={'Link to LinkedIn Recommendation'}
               href={testimonial.link}
               target="_blank"
               rel="noopener noreferrer"
               onClick={() => {
                 useGtag(
                   'event',
                   'click',
                   'landing',
                   { name: 'link-to-recommendation-source' },
                 );
               }}
            >
              <Avatar src={testimonial.profile} alt={testimonial.name + `profile image`}/>
              <div sx={{ lineHeight: 'none', '> h4, > p': {margin: 2, lineHeight: 'none',}}}>
                <h4>{testimonial.name}</h4>
                <p>{testimonial.title}, {testimonial.company}</p>
                <p>{testimonial.date}</p>
              </div>
            </a>
            <p>{testimonial.testimonial}</p>
          </div>
        ),
      )}
    </div>);
};

export default Testimonials;
